body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

b, strong, h1, h2, h3, h4, h5, h6 {
    color: #003468;
}

h1, h2 {
    padding-bottom: 1rem;
}

h2 {
    font-size: 1.5rem;
}

h3 {
    padding-bottom: 0.8rem;
    font-size: 1.1rem;
}

.active{
    color:red;
}

.account-nav {
    padding-top:-10rem;
}

.react-datepicker__input-container input {
    width: 7rem;
    display: block;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    color: #495057;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.react-datepicker__input-container input:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.usage-ctr .container .row:first-child .col:nth-child(odd) {
    margin-top: 0.5rem;
    display: inline-block;
}

.usage-ctr .container .row:first-child .col:nth-child(3) p {
    display: inline-block;
}

.usage-ctr .container .row:first-child .col:nth-child(3) {
    margin-left: -2rem;
}

.usage-ctr .container .row:first-child {
    padding:0 0 0.5rem 0;
    width: 125%;
}

.usage-ctr .container .row:nth-child(2) {
    padding-top: 2rem;
    margin-bottom: -1rem;
    font-size: 1.1rem;
    color: #003468;
}

.usage-ctr .container .row .col p {
    color:#003468;
    font-weight: 600;
}

.usage-ctr .row {
    padding: 0.5rem 0;
}

.chartname {
    font-size: 1.2rem;
    padding-bottom: 0;
    margin-bottom: -1.5rem;
    padding-left: 5rem;
    font-weight: 400;
}

.padding-zero {
    padding: 0 !important;
}

.invoice-table table {
    margin-bottom: 0;
}

.invoice-table table tr td {
    color:#212529 !important;
    font-weight: 400 !important;
}

.invoice-table tr td:first-child {
    color:#003468;
    font-weight: 600;
}

.invoice-table table td:first-child {
    text-align: right;
}

.form-fieldnames .col-form-label {
    color:#003468;
    font-weight: 600;
}


